import { metaTags } from "@/constants";
import { ContextProvider } from "@/context";
import { CookieHolder } from "@/sections/cookie-holder";
import "@/styles/globals.scss";
import { getStore, StoreProvider } from "@store/index";
import NextApp, { AppContext, AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { FC, useEffect } from "react";
import "swiper/css";
import Script from "next/script";
import { StickyCTA } from "@/components";

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const RedeclaredAndHacky_Component = Component as FC;
  const store = getStore();

  useEffect(() => {
    if (store && window?.location?.search) {
      const queryObject = window.location.search;
      if (queryObject) {
        store.setQueryString(new URLSearchParams(queryObject));
      }
    }
  }, [store]);

  return (
    <>
      <Head>
        <title>{metaTags.title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <StoreProvider value={store}>
        <ContextProvider>
          <div style={{ position: "relative", zIndex: 0 }}>
            <RedeclaredAndHacky_Component {...pageProps} />
          </div>
          <StickyCTA />
          <CookieHolder />
        </ContextProvider>
      </StoreProvider>

      <Script type="text/javascript" src={`${process.env.BASE_PREFIX}script.js`} />

      <Script
        defer
        strategy="lazyOnload"
        id="handtalk"
        src="https://plugin.handtalk.me/web/latest/handtalk.min.js"
        onLoad={() => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.ht = new HT({
            token: "5e678207a1f3d846ea77640b9216dd4f",
            avatar: "MAYA",
            opacity: 0,
            highContrast: true,
            mobileConfig: {
              align: "top",
            },
          });
        }}
      />
    </>
  );
};

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<{
  pageProps: AppProps["pageProps"];
}> => {
  const appProps = await NextApp.getInitialProps(appContext);
  return { ...appProps };
};

export default dynamic(() => Promise.resolve(MyApp), {
  ssr: false,
});
